<template>
    <section class="country-slice" aria-label="locale-selection">
        <div class="countries">
            <button
                v-for="country in slice.items"
                :key="`slice-country-${country.countryKey}`"
                type="button"
                :class="{selected: country.countryKey === selectedCountry?.countryKey}"
                @click="setSelectedCountry(country)"
            >
                <span>
                    <OptimizedImage :prismic-image="country.countryIcon" height="14" width="14" :alt="country.countryKey" />
                </span>
                <span>{{ `${country.countryName} (${country.countryKey})` }}</span>
            </button>
        </div>
        <PrismicRichText :field="slice.primary.langSelectionTitle" class="lang-header" :class="{hidden: selectedCountry === null}" />
        <div class="langs" :class="{hidden: selectedCountry === null}">
            <button
                v-for="lang in selectedCountryLanguages()"
                :key="`slice-lang-${lang.langKey}`"
                type="button"
                :class="{selected: lang.langKey === selectedLang?.langKey}"
                @click="setSelectedLang(lang)"
            >
                <span>{{ `${lang.langName} (${lang.langKey})` }}</span>
            </button>
        </div>
    </section>
    <div class="country-slice__footer">
        <VJoyButton
            :disabled="!selectedCountry || !selectedLang"
            data-testid="save-country-selection-choice"
            @click="onSubmit(selectedLang, selectedCountry)"
        >
            {{ slice.primary.saveButtonText }}
        </VJoyButton>
    </div>
</template>

<script setup lang="ts">
    import {inject, ref, onMounted, onBeforeUnmount} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import isFunction from 'lodash-es/isFunction';
    import {navigateTo, useLocale, useSupportedHosts} from '#imports';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {CountrySliceSlice} from '@prismicio-types';
    import type {Country} from '@navbar-unlogged/composables';
    import OptimizedImage from '@navbar-unlogged/components/OptimizedImage.vue';
    import {navbarUnloggedKeys} from '@navbar-unlogged/keys';
    import {eventBus} from '@navbar-unlogged/events';

    type Lang = {
        langKey: string;
        langName: string;
    };

    const translateUrl = inject(navbarUnloggedKeys.TRANSLATE_URL_FN, undefined);

    const props = defineProps({
        ...getSliceComponentProps<CountrySliceSlice>(),
    });

    const {findNewHost} = useSupportedHosts();
    const {locale} = useLocale();

    const selectedCountry = ref<Country>();
    const selectedLang = ref<Lang | null>();

    onMounted(() => {
        eventBus.on('navbar-unlogged:reset-selected-host', setSelectedFromLocale);
        setSelectedFromLocale();
    });

    onBeforeUnmount(() => {
        eventBus.off('navbar-unlogged:reset-selected-host', setSelectedFromLocale);
    });

    function setSelectedFromLocale() {
        if (locale.value) {
            const [currentLang, currentCountryCode] = locale.value.split('-');
            selectedCountry.value = props.slice.items.find((item) => item.countryKey?.toUpperCase() === currentCountryCode?.toUpperCase());
            selectedLang.value = selectedCountryLanguages().find((lang) => lang.langKey.toUpperCase() === currentLang?.toUpperCase());
        }
    }

    function setSelectedCountry(country: Country) {
        selectedCountry.value = country;
        selectedLang.value = null;
    }

    function setSelectedLang(lang: Lang) {
        selectedLang.value = lang;
    }

    async function onSubmit(lang: Lang | null | undefined, country: Country | undefined) {
        if (!lang || !lang.langKey) {
            throw new Error(`langKey should not be null: ${lang?.langKey}`);
        }
        if (!country || !country.countryKey) {
            throw new Error(`countryKey should not be null: ${country?.countryKey}`);
        }

        // When URLs are handled by Prismic
        if (isFunction(translateUrl) && lang && country) {
            const url = await translateUrl(lang.langKey, country.countryKey!);
            return navigateTo(url, {external: true});
        }

        // When unlogged
        const newHost = await findNewHost(country.countryKey, lang.langKey);
        const url = new URL(window.location.href);
        url.hostname = newHost;
        return navigateTo(url.toString(), {external: true});
    }

    function isLang(object: any): object is Lang {
        return !!object.langKey && !!object.langName;
    }

    function selectedCountryLanguages(): Lang[] {
        return [
            {
                langKey: selectedCountry?.value?.lang1Key,
                langName: selectedCountry?.value?.lang1Name,
            },
            {
                langKey: selectedCountry?.value?.lang2Key,
                langName: selectedCountry?.value?.lang2Name,
            },
            {
                langKey: selectedCountry?.value?.lang3Key,
                langName: selectedCountry?.value?.lang3Name,
            },
        ].filter(isLang);
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    @mixin grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: start;
        justify-items: start;
        row-gap: var(--joy-core-spacing-4);
        color: var(--joy-color-neutral-50);
        @include mq.screen_sm(min) {
            row-gap: var(--joy-core-spacing-6);
        }
    }

    .country-slice {
        .hidden {
            display: none;
        }

        .country-header {
            margin-bottom: 20px;
        }

        :deep(img) {
            width: 14px;
            height: 14px;
            object-fit: cover;
            object-position: center;
            overflow: hidden;
            margin-right: 10px;
        }

        .countries {
            margin-bottom: var(--joy-core-spacing-9);
            @include grid;
        }

        .countries > :deep(button),
        .langs > :deep(button) {
            font-family: var(--joy-font-family);
            padding: 9px 15px 9px 9px;
            border: none;
            text-align: left;
            display: flex;
            font-weight: var(--joy-font-weight-bold);
            font-size: var(--joy-font-size-primary-400);

            @include mq.screen_sm(min) {
                font-size: var(--joy-font-size-primary-600);
            }
            span {
                line-height: var(--joy-core-line-height-large);
            }
            &:not(.selected):hover {
                cursor: pointer;
                span {
                    color: var(--joy-color-neutral-50);
                    text-decoration-line: underline;
                }
            }
        }
        .selected {
            background-color: var(--joy-color-secondary-10);
            color: var(--joy-color-secondary-30);
            border-radius: 6px;

            span {
                color: inherit;
            }
        }

        .lang-header {
            margin-bottom: 15px;
            :deep(h2) {
                font-family: var(--joy-font-family);
                font-size: var(--joy-font-size-primary-600);
            }
        }

        .langs {
            @include grid;
        }

        &__footer {
            text-align: right;
            margin-top: var(--joy-core-spacing-4);
        }
    }
</style>
