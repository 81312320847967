import {useLogger, useUniversalFetch} from '#imports';
import type {SupportedCountryResource, SupportedHostResource} from '../types';

export function useSupportedHosts() {
    const logger = useLogger();

    async function getCurrentHost(): Promise<SupportedHostResource> {
        const currentSupportedHost = await useUniversalFetch<SupportedHostResource>('/api/supported-host/current');
        return currentSupportedHost;
    }
    async function findNewHost(countryCode: string, languageCode: string): Promise<string> {
        const currentHostname = window.location.hostname;

        try {
            const supportedHosts = await useUniversalFetch<SupportedCountryResource[]>('/api/supported-host/l10n/hosts');
            const countryCodeUpper = countryCode.toUpperCase();
            const languageCodeLower = languageCode.toLowerCase();
            const supportedHost = supportedHosts.find((h) => h.countryCode === countryCodeUpper);

            if (!supportedHost) {
                logger.error(`No supported host found for country code "${countryCodeUpper}"`);
                return currentHostname;
            }

            const language = supportedHost.languages.find((l) => l.language === languageCodeLower);

            if (!language) {
                logger.error(`No supported host found for language code "${languageCodeLower}"`);
                return currentHostname;
            }

            return language.host;
        } catch (err) {
            logger.error(`Could not fetch supported hosts: ${err}`);
            return currentHostname;
        }
    }

    return {
        findNewHost,
        getCurrentHost,
    };
}
